import React from "react";
import Logo from '../../Images/Icons/Logo.gif'
import { VERSION_APP } from "../../Utill/Config";

function SplashScreen() {

    return (
        <>
            <div style={{ background: `white`, position: `absolute`, width: `100%`, height: `100%`, display: `flex`, justifyContent: `center`, alignItems: `center`, flexDirection: `column` }}>
                <img src={Logo} alt="" style={{ width: 280 }} />
            </div>

            <div style={{ position: `absolute`, width: `100%`, bottom: 15, textAlign: `center`, fontSize: 14 }}>
                version {VERSION_APP}
            </div>


        </>
    )
}
export default SplashScreen;
