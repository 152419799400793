import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { message, Input, Spin } from "antd";
import { API_URL } from "../../Utill/Config";
const liff = window.liff

function AffiliateScreen() {
    const [spinning, setSpinning] = useState(true);
    const [userDetail, setUserDetail] = useState();
    const [svgData, setSvgData] = useState('');
    const [copied, setCopied] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const key = 'updatable';
    const navigate = useNavigate()

    useEffect(() => {
        const initializeLiff = async () => {
            try {
                await liff.init({ liffId: API_URL.LINE_LIFF });

                if (liff.isLoggedIn()) {
                    try {
                        const profile = await liff.getProfile();
                        setUserDetail(profile)
                        axios.get('https://public-api.qr-code-generator.com/v1/create/free', {
                            params: {
                                image_format: 'SVG',
                                image_width: 500,
                                foreground_color: '#000000',
                                frame_color: '#000000',
                                frame_name: 'qrcg-scan-me-bottom-frame',
                                qr_code_logo: '',
                                qr_code_pattern: 'rounded-3',
                                qr_code_text: `https://liff.line.me/${API_URL.LINE_LIFF}/?r=${profile.userId}`
                            },
                            headers: {
                                'accept': 'application/json',
                            }
                        })
                            .then(response => {
                                setSvgData(response.data);
                                setSpinning(false)
                            })
                            .catch(error => {
                                console.error(error);
                            });

                    } catch (err) {
                        console.error("%cERROR LINEOA DETAIL", "color: red", err);
                    }
                } else {
                    const queryString = window.location.search;
                    const redirectUri = `${window.location.origin}${window.location.pathname}${queryString}`;
                    liff.login({ redirectUri });
                }
            } catch (err) {
                console.error("%cERROR LINEOA HEADER", "color: red", err.code, err.message);
            }
        };

        initializeLiff();

    }, []);

    useEffect(() => {
        if (copied === true) {
            messageApi.open({
                key,
                type: 'loading',
                content: 'รอสักครู่...',
            });
            setTimeout(() => {
                messageApi.open({
                    key,
                    type: 'success',
                    content: 'คัดลอกลิ้งเรียบร้อย!',
                    duration: 2,
                });

            }, 500);

            setCopied(false)
        }
        // eslint-disable-next-line
    }, [copied]);

    function shareMessageToLine() {
        setSpinning(true)

        if (liff.isLoggedIn()) {
            try {
                liff
                    .shareTargetPicker([
                        {
                            "type": "flex",
                            "altText": `อยากได้ตัวช่วยคิดคอนเทนต์ปังๆ มั้ย มาลองใช้กัน !!!`,
                            "contents": {
                                "type": "bubble",
                                "direction": "ltr",
                                "hero": {
                                    "type": "image",
                                    "url": "https://img5.pic.in.th/file/secure-sv1/46df7935097411742.png",
                                    "size": "full",
                                    "aspectRatio": "9:3",
                                    "aspectMode": "cover"
                                },
                                "body": {
                                    "type": "box",
                                    "layout": "vertical",
                                    "contents": [
                                        {
                                            "type": "text",
                                            "text": "อยากได้ตัวช่วยคิดคอนเทนต์ปังๆ มั้ย\nมาลองใช้กัน !!!",
                                            "align": "center",
                                            "wrap": true,
                                            "contents": []
                                        }
                                    ]
                                },
                                "footer": {
                                    "type": "box",
                                    "layout": "horizontal",
                                    "contents": [
                                        {
                                            "type": "button",
                                            "action": {
                                                "type": "uri",
                                                "label": "ทดลองใช้ฟรี",
                                                "uri": `https://liff.line.me/${API_URL.LINE_LIFF}/?r=${userDetail?.userId}`
                                            },
                                            "style": "primary"
                                        }
                                    ]
                                }
                            }
                        }
                    ],
                        {
                            isMultiple: true,
                        })
                    .then(function (res) {
                        setSpinning(false)

                        if (res) {

                            messageApi.open({
                                key,
                                type: 'loading',
                                content: 'รอสักครู่...',
                            });
                            setTimeout(() => {
                                messageApi.open({
                                    key,
                                    type: 'success',
                                    content: 'แชร์ให้เพื่อนเรียบร้อย!',
                                    duration: 2,
                                });

                            }, 500);

                        }
                    })
                    .catch(function (error) {
                        setSpinning(false)

                        messageApi.open({
                            key,
                            type: 'loading',
                            content: 'รอสักครู่...',
                        });
                        setTimeout(() => {
                            messageApi.open({
                                key,
                                type: 'error',
                                content: error,
                                duration: 2,
                            });

                        }, 500);
                    });

            } catch (err) {
                console.error("%cERROR LINEOA DETAIL", "color: red", err);
            }
        }
    }

    return (
        <>
            <Spin spinning={spinning} fullscreen />
            {contextHolder}
            <div style={{ backgroundColor: `#eff0f7`, height: `100%`, position: `absolute`, width: `100%` }}>
                <div style={{ padding: 20 }}>
                    <div onClick={() => { navigate('/') }} style={{ backgroundColor: `grey`, width: 90, padding: 5, borderRadius: 50, textAlign: `center`, fontSize: 18, color: `white` }}>
                        ❮ กลับ
                    </div>

                    <div style={{ marginTop: 20, backgroundColor: `white`, padding: 20, borderRadius: 7, fontSize: 20, fontWeight: `bold`, display: `flex`, justifyContent: `center`, alignItems: `center`, flexDirection: `column`, boxShadow: `2px 2px 10px 0px #9b9b9b` }}>
                        <div>
                            แชร์ให้เพื่อนสมัครสมาชิก ทางไลน์
                        </div>
                        <div onClick={() => { shareMessageToLine() }} style={{ backgroundColor: `#479e4d`, width: 170, padding: 5, borderRadius: 50, textAlign: `center`, fontWeight: `bold`, fontSize: 18, color: `white`, marginTop: 5, boxShadow: `2px 2px 5px 0px #9b9b9b` }}>
                            แชร์ให้เพื่อน
                        </div>
                    </div>

                    <div style={{ marginTop: 20, backgroundColor: `white`, padding: 20, borderRadius: 7, fontSize: 20, fontWeight: `bold`, display: `flex`, justifyContent: `center`, alignItems: `center`, flexDirection: `column`, boxShadow: `2px 2px 10px 0px #9b9b9b` }}>
                        <div>
                            ใช้ QR Code ให้เพื่อนสแกน
                        </div>
                        <div style={{ backgroundColor: `#479e4d`, width: 170, padding: 5, borderRadius: 10, textAlign: `center`, fontSize: 14, color: `white`, marginTop: 5, boxShadow: `2px 2px 5px 0px #9b9b9b` }}>
                            {svgData ? (
                                <img src={`data:image/svg+xml;base64,${btoa(svgData)}`} style={{ height: `100%` }} alt="QR Code" />
                            ) : (
                                <p>Loading QR code...</p>
                            )}
                        </div>
                    </div>

                    <div style={{ marginTop: 20, backgroundColor: `white`, padding: 20, borderRadius: 7, fontSize: 20, fontWeight: `bold`, display: `flex`, justifyContent: `center`, alignItems: `center`, flexDirection: `column`, boxShadow: `2px 2px 10px 0px #9b9b9b` }}>
                        <div>
                            ลิ้งค์แนะนำของคุณ
                        </div>
                        <CopyToClipboard text={`https://liff.line.me/${API_URL.LINE_LIFF}/?r=${userDetail?.userId}`} onCopy={() => setCopied(true)}>
                            <div style={{ padding: 5, textAlign: `center`, fontSize: 14, marginTop: 5, display: `flex`, flexDirection: `row`, alignItems: `center`, width: `90%` }}>
                                <Input value={`https://liff.line.me/${API_URL.LINE_LIFF}/?r=${userDetail?.userId}`} readOnly />
                            </div>
                        </CopyToClipboard>

                    </div>
                </div>
            </div >

        </>
    )
}
export default AffiliateScreen;
