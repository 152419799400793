export const VERSION_APP = `2.0.1`

export const API_URL_ENV = {
    DEV: {
        BASE_API: 'https://script.google.com/macros/s/AKfycbzAqAYgK5dd9ioKdj80LQ9CqqZn-N628-nuZs5jwjL4cNODaNh3T5uGCT-4macpTBc/exec',
        LINE_LIFF: '2005674165-BqrQ04p7',
        LINEOA_CHANNEL: `https://line.me/ti/p/@208bossx`,
        DIFY_URL: `https://udify.app/chat/5fiK9TYI2b0evd8h`,
        ENV: `DEV`
    },
    PROD: {
        BASE_API: 'https://script.google.com/macros/s/AKfycbyWXGBESm_UZAm1wOzLJMa0o0hQ6emQIWrbe-fEiGpvSAu6OyVVqf2fAisPH5mT5u8VUw/exec',
        LINE_LIFF: '2005575016-bwOQLMnW',
        LINEOA_CHANNEL: `https://line.me/ti/p/@291ftqcm`,
        DIFY_URL: `https://udify.app/chat/5fiK9TYI2b0evd8h`,
        ENV: `PROD`
    }
}
export const API_URL = API_URL_ENV.PROD