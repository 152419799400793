import axios from "axios";
import React, { useEffect, useState } from "react";
import { Result, Button, Spin, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../Utill/Config";
import SplashScreen from "../SplashScreen/SplashScreen";

const liff = window.liff

function MainScreen() {
    const [enableFrame, setEnableFrame] = useState(null);
    const [spinning, setSpinning] = useState(true);
    const [userDetail, setUserDetail] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate()

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const initializeLiff = async () => {
            try {
                await liff.init({ liffId: API_URL.LINE_LIFF });

                if (liff.isLoggedIn()) {
                    try {
                        const profile = await liff.getProfile();
                        const queryString = window.location.search;
                        const urlParams = new URLSearchParams(queryString);
                        const getParam = urlParams.get('r');

                        if (liff.isInClient() && API_URL.ENV === `PROD`) {
                            // console.log("%cPROFILE LINEOA : ", "color: green", profile);
                            if (getParam) {
                                fetchData(profile.userId, profile.displayName, getParam);
                            } else {
                                fetchData(profile.userId, profile.displayName, ``);
                            }
                        } else if (API_URL.ENV === `DEV`) {
                            console.log("%cPROFILE LINEOA : ", "color: red", profile);
                            if (getParam) {
                                fetchData(profile.userId, profile.displayName, getParam);
                            } else {
                                fetchData(profile.userId, profile.displayName, ``);
                            }
                        } else {
                            console.log("%cPROFILE LINEOA : ", "color: red", profile);
                        }
                    } catch (err) {
                        console.error("%cERROR LINEOA DETAIL", "color: red", err);
                    }
                } else {
                    const queryString = window.location.search;
                    const redirectUri = `${window.location.origin}${window.location.pathname}${queryString}`;
                    liff.login({ redirectUri });
                }
            } catch (err) {
                console.error("%cERROR LINEOA HEADER", "color: red", err.code, err.message);
            }
        };

        initializeLiff();
        // eslint-disable-next-line
    }, []);

    const fetchData = async (userId, displayName, ref) => {
        try {
            const response = await axios.get(`${API_URL.BASE_API}?userId=${userId}&displayName=${displayName}&ref=${ref}`);
            if (response.data.code === 200) {
                if (response.data?.register === 1) {
                    navigate(`/register`, { replace: true })
                    return
                }
                setUserDetail(response.data)
                if (response.data.status === 0) {
                    setEnableFrame(false)
                } else {
                    setEnableFrame(true)
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setSpinning(false)
        }
    };

    function backToChat() {
        liff.closeWindow()
    }

    return (
        <>

            <Modal title="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} centered>
                <img src={`https://img5.pic.in.th/file/secure-sv1/47cc1e4bd8da505a2fc0ec947702dfed.png`} style={{ height: `100%`, width: `100%`, borderTopLeftRadius: 8, borderTopRightRadius: 8 }} alt="QR Code" />
            </Modal>
            <Spin spinning={spinning} fullscreen />
            {enableFrame === true ?
                <>
                    <div style={{ display: `flex`, justifyContent: `end`, alignItems: `center`, flexDirection: `row`, padding: 10 }}>
                        <div onClick={() => { navigate('/affiliate') }} style={{ backgroundColor: `#e6f1f3`, padding: `7px 10px`, borderRadius: 7, width: 145, display: `flex`, justifyContent: `center`, alignItems: `center`, boxShadow: `rgb(175 175 175) 2px 2px 10px 0px` }}>
                            <span role="img" aria-label="icon-money">💸 <span style={{ fontWeight: `bold`, marginLeft: 5, fontSize: 14 }}>แชร์รับรายได้</span>
                            </span>
                        </div>
                        <div style={{ backgroundColor: `#e6f1f3`, padding: `7px 10px`, borderRadius: 7, marginLeft: 5, fontSize: 14, boxShadow: `rgb(175 175 175) 2px 2px 10px 0px` }}>
                            <span role="img" aria-label="icon-calendar">🗓️ <span style={{ fontWeight: `bold`, marginLeft: 5, fontSize: 16 }}>{userDetail?.dayBeforeExpire ? userDetail?.dayBeforeExpire : 0} </span>วัน
                            </span>
                        </div>
                        <div onClick={() => { showModal() }} style={{ backgroundColor: `#fce9d4`, padding: `7px 10px`, borderRadius: 7, marginLeft: 5, fontSize: 14, boxShadow: `rgb(175 175 175) 2px 2px 10px 0px` }}>
                            <span style={{ fontWeight: `bold`, fontSize: 16 }}>{userDetail?.point ? userDetail?.point : 0} </span> คะแนน
                        </div>
                    </div>
                    <iframe
                        src={API_URL.DIFY_URL} title="ContentMaitan"
                        style={{ width: `100%`, height: `calc(89vh - 0px)` }}
                        frameBorder="no"
                        allow="microphone">
                    </iframe>
                </>
                : enableFrame === false ?
                    <>
                        <div className="container">
                            <Result
                                status="403"
                                title="ไม่สามารถเข้าใช้งานได้"
                                subTitle="วันของคุณหมดแล้ว กรุณาเติมวันเพื่อให้ใช้งานได้อย่างต่อเนื่อง"
                                extra={<Button onClick={() => { backToChat() }} type="primary">ติดต่อแอดมินเพื่อเติมวันใช้งาน</Button>}
                            />
                        </div>
                    </>
                    : <SplashScreen />}

        </>
    )
}
export default MainScreen;
